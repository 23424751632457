import { mapGetters } from 'vuex';

export default {
  data: function() {
    return {};
  },
  computed: {
    ...mapGetters({
      userActivities: 'settingUser/userActivities',
    }),
    isAuthEmailFlagDisabled() {
      return this.$permission.isOffice() && !!this.userDetail.onAuthEmailByMasterFlag;
    },
  },
  watch: {
    'formData.authEmailFlag' (val) {
      if (this.isAuthEmailFlagDisabled && this.userDetail?.authEmailFlag && !val) {
        this.$message.showError('notChangeWhenOnAuthEmailFlag');
        this.$nextTick(() => {
          this.formData.authEmailFlag = 1;
        });
      }
    }
  },
  methods: {
    updateData(data) {
      this.formData = { ...this.formData, ...data };
    },
    async getActivities() {
      await this.$store.dispatch('settingUser/getUserActivities', {
        limit: 1000,
        id: this.id
      });
    },
  },
  async mounted() {
    await this.getActivities();
  }
}
